
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
// import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
// import accountModule from '@/store/modules/accountModule';
// import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
// import tasqsListModule from '@/store/modules/tasqsListModule';
import metaDataModule from '@/store/modules/metaDataModule';
import proceduresModule from '@/store/modules/proceduresModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class Groups extends Vue {
   @Prop({ type: Object, required: false }) action?: any;

   windowWidth = window.innerWidth;

   activeTableID: any = null;

    customSignalRange: any = { startDate: null, endDate: null };

   actionCategory = 'None';

    dataLoadingBackend = false;

   activeWorkspaceID: any = null;

   dropdownSystemLocation: any = []

    dropdownSymptomsLocation: any = []

     dropdownCausesLocation: any = []

   	isHoveringIndex = -1;

   errors: any = []

   deleteGroupPopup = false;

   actionName: any = '';

   actionPayload: any = {
     sympotoms: [],
     causes: [],
     category: '',
     system: [],
     inputs: [],
     finalSteps: [],
     completedForToday: true,
   }

   availableSympotoms: any = []

   availableSystems: any = []

   dropdownSystemSelected: any = []

  dropdownSymptomsSelected: any = []

  dropdownCausesSelected: any = []

   availableCauses: any = []

    friendlyLayerName = '';

   deleteGroupParams: any = {};

   availableSearchOptions: any = [];

   addNewActionOption(input, option) {
     //  this.actionPayload;
     //  nput.options.push(input.newVal); input.newVal = ''
     this.actionSelectedInputs = this.actionSelectedInputs.map((data) => {
       if (data.Name === input.Name) {
         data.options.push(option);
         // eslint-disable-next-line no-param-reassign
         data.newVal = '';
         // eslint-disable-next-line no-param-reassign
       }
       return data;
     });
     this.$forceUpdate();
   }

   removeNewActionOption(input, option) {
     //  this.actionPayload;
     //  nput.options.push(input.newVal); input.newVal = ''
     this.actionSelectedInputs = this.actionSelectedInputs.map((data) => {
       if (data.Name === input.Name) {
         // eslint-disable-next-line no-param-reassign
         data.options = data.options.filter((e) => e !== option);
         // eslint-disable-next-line no-param-reassign
         data.newVal = '';
         // eslint-disable-next-line no-param-reassign
       }
       return data;
     });
   }

   newAttributePayload: any = [];

   get getStylesForNext() {
     if (this.stepNo === 4 && !this.actionSelectedInputs.length) {
       return 'background: #b7b7b7; cursor: no-drop';
     } if (this.stepNo === 3 && this.actionName === '') {
       return 'background: #b7b7b7; cursor: no-drop';
     }
     if (this.stepNo === 0 && !this.actionName) {
       return 'background: #b7b7b7; cursor: no-drop';
     }
     return 'background: #5be5c1; width: 205px;';
   }

   get getDisableForNext() {
     if (this.stepNo === 4 && !this.actionSelectedInputs.length) {
       return true;
     } if (this.stepNo === 3 && this.actionName === '') {
       return true;
     }
     if (this.stepNo === 0 && !this.actionName) {
       return true;
     }
     //  console.log(this.actionName);
     if (this.actionName === '') {
       this.errors.push('actionName');
     } else {
       this.errors = [];
     }

     return false;
   }

   stepNo: any = 0;

   newColumnName = '';

  headerText: any = ['Upload your assets', 'Organize your assets', 'Tag your assets', 'Match column labels to asset information'];

  nextButtonText: any = ['Next', 'Next', 'Next', 'Next', 'Save'];

  showCreateGroupModal =false;

  attributeSearchQuery = '';

  get attributeTypeOptions() {
    return [
      { text: 'Text Field', value: 'TEXT' },
      { text: 'Date', value: 'TIMESTAMP' },
      { text: 'Number', value: 'FLOAT' },
      { text: 'Checkboxes', value: 'FUNCTION' },
      { text: 'Images/Video', value: 'BLOB' },
      { text: 'PDF Procedure', value: 'BLOB' },
    ];
  }

  searchDataLoading = false;

noAttributeFound= true;

  searchResultWells: any =[]

  foundWellNames: any = [];

  availableWellNames = [];

  tempGroups = []

  searchTerms: any = [];

  fileExtractedData: any = [];

  totalNoWellRows: any = 0;

  searchTermsJson: any = [];

  actionSelectedInputs: any = [];

  async nextStep() {
    this.dataLoading = true;

    console.log(this.selectedAttribute);
    if (this.stepNo === 0 && this.action.ID && this.action.actionPayload) {
      this.stepNo = 3;
    // eslint-disable-next-line no-empty
    } else if (this.stepNo === 0 && !this.actionName) {

    } else if (this.stepNo === 1 && !this.selectedAttribute.Name) {
      if (this.selectedAttribute.AttributeTypeName === null) {
        this.selectedAttribute.AttributeTypeName = 'NUMBER';
      }
      if (['date', 'blob', 'function', 'number', 'float'].includes(this.selectedAttribute.AttributeTypeName.toLowerCase())) {
        this.stepNo = 3;
      }
      this.stepNo = 3;
    } else if (this.stepNo === 1) {
      console.log(this.selectedAttribute);
      if (this.selectedAttribute.AttributeTypeName === null) {
        this.selectedAttribute.AttributeTypeName = 'NUMBER';
      }
      if (['date', 'blob', 'function', 'number','timestamp', 'integer', 'float'].includes(this.selectedAttribute.AttributeTypeName.toLowerCase())) {
        if (this.selectedAttribute.AttributeTypeName.toLowerCase() === 'timestamp') {
          this.selectedAttribute.type = 'Date';
        } else if (this.selectedAttribute.AttributeTypeName.toLowerCase() === 'blob') {
          this.selectedAttribute.type = 'File Upload';
        } else if (this.selectedAttribute.AttributeTypeName.toLowerCase() === 'function') {
          this.selectedAttribute.type = 'Checkbox';
        } else if (this.selectedAttribute.AttributeTypeName.toLowerCase() === 'number') {
          this.selectedAttribute.type = 'Number Input';
        } else if (this.selectedAttribute.AttributeTypeName.toLowerCase() === 'float') {
          this.selectedAttribute.type = 'Number Input';
        }

        this.selectedAttribute.showEdit = false;
        if (!this.selectedAttribute.options) {
          this.selectedAttribute.options = [];
        }
        if (this.selectedAttribute.ID) {
          this.actionSelectedInputs.push(this.selectedAttribute);
        }
        this.stepNo = 3;
      } else {
        this.stepNo = 2;
      }
    } else if (this.stepNo === 2) {
      this.selectedAttribute.showEdit = false;
      if (!this.selectedAttribute.options) {
        this.selectedAttribute.options = [];
      }
      if (this.selectedAttribute.ID) {
        this.actionSelectedInputs.push(this.selectedAttribute);
      }
      // this.action.inputs.push(this.selectedAttribute)
      this.stepNo += 1;
    } else if (this.stepNo === 4) {
      this.actionSelectedInputs.map((data) => {
        // eslint-disable-next-line no-param-reassign
        data.showEdit = false;
        return data;
      });
      this.actionPayload.inputs = this.actionSelectedInputs;
      // console.log(this.actionPayload);
      if (this.action.ID) {
        // this.$emit('data-update-popup');
        await metaDataModule.putActionBuilder({
          id: this.action.ID, name: this.actionName, category: this.actionCategory, payload: this.actionPayload,
        });
      } else {
        await metaDataModule.postActionBuilder({ name: this.actionName, category: this.actionCategory, payload: this.actionPayload });
      }
      this.$emit('data-update-popup');
    } else {
      this.stepNo += 1;
    }
    setTimeout(() => {
      this.dataLoading = false;
    }, 2000);
  }

  addAnotherInput() {
    // console.log(this.actionSelectedInputs);
    this.selectedAttribute = {};
    this.stepNo = 1;
  }

  showModal = false;

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags: any = []

   showWellMenu = false;

   dataLoading = false;

   foundSearchResult = false;

   selectedAttribute: any = {};

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   showSearchPopup = false;

   newCloumnType = '';

   knowAttributesData: any = []

     dropdownLocation: any = [];

     get knowAttributes() {
       return this.knowAttributesData.filter((attr) => {
         //  console.log('test');
         if (this.attributeSearchQuery) {
           return attr.Name.toLowerCase().includes(this.attributeSearchQuery.toLowerCase());
         }
         return true;
       });
     }

     removeActionInput(input) {
       this.actionSelectedInputs = this.actionSelectedInputs.filter((data) => data.Name !== input.Name);
     }

     editActionInput(input) {
       this.actionSelectedInputs = this.actionSelectedInputs.map((data) => {
         if (data.Name === input.Name) {
           // eslint-disable-next-line no-param-reassign
           data.showEdit = !data.showEdit;
         }
         return data;
       });
     }

     didSelectDropdown(e, columnName) {
       // if (type == 'Status Changes' || type == 'Duplicate Tasq') {
       // console.log(e)
       const rect = e.srcElement.parentElement.getBoundingClientRect();
       this.dropdownLocation = [rect.x + 195, rect.y + 75];

       // } else {
       // 	const rect = e.srcElement.getBoundingClientRect()
       // 	this.dropdownLocation = [rect.x - 13, rect.y + 60]
       // }
     }

     didSelectSystemsDropdown(e) {
       const rect = e.srcElement.parentElement.getBoundingClientRect();
       this.dropdownSystemLocation = [rect.x + 190, rect.y + 50];

     }

     hideSystemsDropdown() {
        this.actionPayload.system = (this.dropdownSystemSelected)
       this.dropdownSystemLocation = [];
     }


     	async didSystemSelectDropdownOption() {

      //  this.dropdownLocation = [];
     }


  	async didSymptomsSelectDropdownOption() {

      //  this.dropdownLocation = [];
     }


        didSelectSymptomsDropdown(e) {
       const rect = e.srcElement.parentElement.getBoundingClientRect();
       this.dropdownSymptomsLocation = [rect.x + 190, rect.y];

     }

     hideSymptomsDropdown() {
        this.actionPayload.sympotoms = (this.dropdownSymptomsSelected)
       this.dropdownSymptomsLocation = [];
     }

  	async didCausesSelectDropdownOption() {

      //  this.dropdownLocation = [];
     }

        didSelectCausesDropdown(e) {
       const rect = e.srcElement.parentElement.getBoundingClientRect();
       this.dropdownCausesLocation = [rect.x + 190, rect.y - 80];

     }

     hideCausesDropdown() {
        this.actionPayload.causes = (this.dropdownCausesSelected)
       this.dropdownCausesLocation = [];
     }


     didHoverChecklist(index) {
       this.isHoveringIndex = index;
     }

     didLeaveHoverChecklist(index) {
       this.isHoveringIndex = -1;
     }

  	checkMoveChecklist(event) {
       // console.log(event);
       const { draggedContext } = (event);
       if ((draggedContext.element)) {
         const index = (this.actionSelectedInputs.findIndex((input) => input.ID === draggedContext.element.ID));

         this.actionSelectedInputs[index].priority = index;

         console.log(this.actionSelectedInputs);
       }
       // this.activeMoveTasqID = (draggedContext.element.id);
     }

  	async didSelectDropdownOption() {
       this.dataLoading = true;
       this.dropdownLocation = [];
       if (this.newAttributePayload && this.newAttributePayload[0]) {
         const data = this.newAttributePayload[0];

         await metaDataModule.postKnownAttributes({ name: this.attributeSearchQuery, type: data.value });
         this.knowAttributesData = await metaDataModule.fetchKnownAttributes();
       }
       setTimeout(() => {
         this.dataLoading = false;
       }, 1000);
     }

     hideDropdown() {
       this.dropdownLocation = [];
     }

     selectAttribute(attribute) {
       this.selectedAttribute = attribute;
     }

     async mounted() {
       this.$nextTick(() => {
         window.addEventListener('resize', this.onResize);
       });

       let tags: any = [];
       const allWellTags = scheduleModule.availableTags;
       //  console.log(allWellTags);
       // console.log(allWellTags)
       if (allWellTags.length) {
         tags = tags.concat(allWellTags);
       }

       if (tags && tags.length) {
         this.availableTags = [...new Set(tags)].sort();
       }
       const wellsList: any = assetsModule.enabledWells;
       this.availableWellNames = wellsList.map((well) => well.wellName);

       const data = await metaDataModule.fetchKnownAttributes();
       console.log(data);
       if (data && data.length) {
         this.knowAttributesData = data;
       }

     //  console.log(this.availableWellNames);
     }

  @Watch('attributeSearchQuery')
     updateAttributeSearchQuery(query) {
       //  console.log(query);
     }

  @Watch('actionSelectedInputs', { immediate: true, deep: true })
  updateAction(query) {
    // console.log(query);
  }

  async created() {
    // console.log(this.action);
    this.dataLoading = true;
    if (this.action.Name) {
      this.actionName = this.action.Name;
      this.actionCategory = this.action.Category;
      this.actionPayload = this.action.actionPayload;
      // console.log(this.action.actionPayload.inputs);
      this.actionSelectedInputs = this.action.actionPayload.inputs;
    }

    this.availableSystems.sort();
    // this.getAvailableOptions();

    this.dataLoading = false;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  formatBubbleText(val) {
    return this.toTitleCase(val.toLowerCase());
  }

 async closeDialog() {
   this.showModal = false;
 }

 onResize() {
   this.windowWidth = window.innerWidth;
 }
}

